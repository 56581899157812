<template>
  <div
    id="blogPost"
    class="q-pb-xl bg-background"
  >
    <div id="fb-root" />
    <QPage v-if="article">
      <div class="row justify-center">
        <div class="col-12 col-md-8 q-mx-auto q-px-md q-gutter-y-md">
          <!-- Breadcrumbs -->
          <div class="q-my-md text-grey-5 text-body1 q-mt-lg">
            <QBreadcrumbs>
              <QBreadcrumbsEl :to="{ name: 'blog' }">
                <AppContent
                  class="text-grey-5"
                  path="navigation.menu.happymedia"
                />
              </QBreadcrumbsEl>
              <QBreadcrumbsEl :to="{ name: 'blog' }">
                <AppContent
                  class="text-grey-5"
                  :path="`navigation.menu.${trnBlog}`"
                />
              </QBreadcrumbsEl>
              <QBreadcrumbsEl
                class="text-grey-7 text-weight-medium"
                :label="$_.truncate(article.fields.title)"
              />
            </QBreadcrumbs>
          </div>

          <!-- Categories -->
          <div class="flex justify-between items-center">
            <div
              v-if="article.metadata.categories"
              class="flex"
            >
              <router-link
                v-for="category in article.metadata.categories"
                :key="category"
                class="hc-link"
                :to="{ name: 'blog', query: { category } }"
              >
                <QChip
                  :label="$t({id:`pages.${trnBlog}.categories.${category}`})"
                  class="text-black text-caption bg-blue-3 q-mr-sm"
                />
              </router-link>
            </div>
          </div>
          <QChip
            v-if="!article.metadata.categories"
            square
            class="q-mx-none q-pa-md q-mb-md no-border-radius text-caption text-black"
            color="warning"
          >
            <AppContent :path="`pages.${trnBlog}.categories.no`" />
          </QChip>

          <!-- Titles -->
          <h1>{{ article.fields.title }}</h1>
          <h2>{{ article.fields.subtitle }}</h2>
          <AppContent
            path="pages.blog.published"
            :options="{ datetime: $t({ id: 'time.date' }, { fmtd: articleDate }) }"
            class="text-grey-5"
          />

          <!-- Cover -->
          <QImg
            width="100%"
            style="max-width:100vw"
            :alt="article.name ?? ''"
            :src="cdnImg($_.get(article, 'fields.cover', $t({ id: 'images/placeholder' })), { quality: 100 })"
            crossorigin="anonymous"
            spinner-color="primary"
            loading="lazy"
            class="br-25"
          />

          <!-- Duration and share -->
          <div class="flex justify-between items-center text-caption text-grey-5 q-py-md">
            <HCButton
              force-label-on-xs
              icon="uil:clock"
              color="grey-5"
              :label="$t({ id: 'pages.blog.read_duration' }, { duration: $_.get(article, 'fields.duration') || 2 })"
              class="text-weight-regular"
            />
            <div class="flex items-center">
              <AppContent
                class="text-black q-mr-md gt-xs"
                path="prompt.share"
              />
              <div class="flex justify-center items-center">
                <QBtn
                  v-for="(link, linkType) of links"
                  :key="linkType"
                  round
                  :color="linkType"
                  :href="link.url"
                  :icon="link.icon"
                  target="blank"
                  size="sm"
                  class="q-mx-xs"
                  @click="cc(link.url)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-center">
        <div
          v-if="!onMobile"
          class="col-2"
        />
        <div class="col-12 col-md-8 q-px-md q-gutter-y-md">
          <!-- Summary -->
          <QCard v-if="titles.length">
            <QCardSection>
              <AppContent
                path="component.builds.article.summary"
                class="text-h6"
              />
              <ul>
                <li
                  v-for="(title, index) of titles"
                  :key="index"
                  class="hc-link cursor-pointer text-weight-medium text-primary q-mt-sm"
                  @click="scrollTo(`titre_${index}`)"
                >
                  {{ title.text }}
                  <ul v-if="$_.get(title, 'children', []).length > 0">
                    <li
                      v-for="(child, i) of $_.get(title, 'children', [])"
                      :key="i"
                      class="hc-link cursor-pointer text-weight-medium text-primary q-mt-sm"
                      @click="scrollTo(`titre_${i}`)"
                    >
                      {{ child }}
                    </li>
                  </ul>
                </li>
              </ul>
            </QCardSection>
          </QCard>

          <!-- Newsletter -->
          <QCard
            flat
            class="bg-purple-2"
          >
            <QCardSection>
              <div class="row q-col-gutter-md">
                <div class="col-3 gt-sm q-pa-md flex items-center">
                  <img
                    :src="cdnImg($t({ id: 'images.footerNewsletter' }), { width: 120 })"
                    loading="lazy"
                    alt="i"
                    class="full-width"
                  >
                </div>
                <div class="col-12 col-md-9">
                  <AppContent
                    class="q-mt-sm q-mb-none text-weight-bold text-h5"
                    path="navigation.footer.menus.newsletter.title"
                  />
                  <AppContent
                    class="q-mt-sm text-body2 text-grey-5"
                    path="navigation.footer.menus.newsletter.text"
                  />
                  <div class="row q-mt-sm items-center">
                    <div class="col-4 q-pr-xs">
                      <QInput
                        v-model="newsletterFirstname"
                        outlined
                        dense
                        type="text"
                        label-color="grey-5"
                        bg-color="white"
                        :label="$t({ id: 'navigation.footer.menus.newsletter.firstname' })"
                      />
                    </div>
                    <div class="col q-pl-xs">
                      <QInput
                        v-model="newsletterEmail"
                        outlined
                        dense
                        type="text"
                        label-color="grey-5"
                        bg-color="white"
                        :label="$t({ id: 'navigation.footer.menus.newsletter.email' })"
                      />
                    </div>
                    <div class="col-2 text-center gt-xs q-pl-sm">
                      <HCBigButton
                        label="navigation.footer.menus.newsletter.submit"
                        @click="submitNewsletter"
                      />
                    </div>
                  </div>
                  <div class="text-center lt-sm q-mt-sm">
                    <HCBigButton
                      label="navigation.footer.menus.newsletter.submit"
                      @click="submitNewsletter"
                    />
                  </div>
                </div>
              </div>
            </QCardSection>
          </QCard>

          <!-- Corpse -->
          <QCard flat>
            <QCardSection>
              <template v-for="(node, index) in nodes">
                <div
                  v-if="['youtube', 'article', 'spotify', 'platformAd'].includes(nodeType(node))"
                  :key="index"
                  class="row justify-center q-my-md"
                >
                  <QCard
                    v-if="nodeType(node) === 'youtube'"
                    flat
                    bordered
                    class="bd-2 bd-grey"
                    style="width:50%"
                  >
                    <QCardSection>
                      <LazyYtVideo :src="embedURL(node)" />
                    </QCardSection>
                  </QCard>
                  <ArticleCard
                    v-else-if="nodeType(node) === 'article'"
                    :article="getRelatedArticle(node)"
                    :class="{'col-6': $q.screen.gt.xs, 'col-12': $q.screen.lt.sm}"
                  />
                  <iframe
                    v-else-if="nodeType(node) === 'spotify'"
                    :src="node.split('=')[1]"
                    loading="lazy"
                    title="i"
                    width="300"
                    height="380"
                    frameborder="0"
                  />
                  <div
                    v-else-if="nodeType(node) === 'platformAd' && platformAdAtIndex(node) && onMobile"
                    class="col-12"
                  >
                    <div class="text-italic text-center text-caption text-grey-5">
                      La suite de votre article après cette publicité
                    </div>
                    <QCard
                      flat
                      class="hc-link br-25 hc-shadow cursor-pointer col-12"
                      @click="handleAdClick(platformAdAtIndex(node))"
                    >
                      <div
                        v-if="String(platformAdAtIndex(node).fields.label).length"
                        class="text-grey-5 text-center text-caption text-italic q-pl-sm"
                      >
                        {{ platformAdAtIndex(node).fields.label ?? '' }}
                      </div>
                      <img
                        :src="cdnImg(platformAdAtIndex(node).fields.media)"
                        loading="lazy"
                        :alt="platformAdAtIndex(node).fields.label ?? 'Pub'"
                        style="width: 100%"
                      >
                      <ActionTooltip
                        v-if="platformAdAtIndex(node).fields.label"
                        :str="platformAdAtIndex(node).fields.label"
                      />
                    </QCard>
                  </div>
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-else
                  :key="`${index}_vhtml`"
                  class="text-body1"
                  v-html="node"
                />
                <!-- eslint-enable vue/no-v-html -->
              </template>
            </QCardSection>
          </QCard>

          <!-- Promotion -->
          <div class="row">
            <div class="col-6 gt-xs text-right">
              <QImg
                :src="cdnImg($t({ id: 'images.article_bottom_promote' }))"
                loading="lazy"
                alt="i"
                style="border-radius:8px"
              />
            </div>
            <div class="col flex flex-center text-center">
              <div class="q-gutter-y-md">
                <AppContent
                  class="text-grey-5"
                  path="pages.blog.offers"
                />
                <HCBigButton
                  label="pages.blog.offers_btn"
                  :to="$web({ name: 'searchOffers' })"
                />
              </div>
            </div>
          </div>

          <!-- Tags -->
          <div class="row">
            <QChip
              v-for="(tag, i) of $_.get(article, 'fields.tags', [])"
              :key="i"
              class="text-black text-caption"
              color="blue-3"
              :label="$_.capitalize(tag)"
            />
          </div>

          <!-- Author & Likes -->
          <div class="row justify-between q-py-md">
            <div
              v-if="$_.get(article, 'fields.author', false)"
              class="flex"
            >
              <QAvatar
                class="gt-xs "
                size="92px"
              >
                <QImg
                  :src="cdnImg(article.fields.author.avatar, { width: 92, height: 92, quality: 75 })"
                  loading="lazy"
                  alt="i"
                  width="92"
                  height="92"
                />
              </QAvatar>
              <div class="q-ml-md flex column justify-around">
                <AppContent
                  class="text-grey-5"
                  :path="`pages.${trnBlog}.author_title`"
                />
                <div class="text-weight-medium flex items-center">
                  <div>
                    {{ article.fields.author.name }}
                  </div>
                  <QBtn
                    v-if="$_.get(article, 'fields.author.linkedin', false)"
                    icon="uil:linkedin"
                    color="linkedin"
                    :href="article.fields.author.linkedin"
                    target="_blank"
                    size="sm"
                    class="social-link"
                    flat
                    dense
                  >
                    <ActionTooltip path="social.linkedin" />
                  </QBtn>
                </div>
                <div>
                  {{ article.fields.author.role }} @ {{ $_.get(article, 'fields.author.company', 'HappyCab') }}
                </div>
              </div>
            </div>
            <div>
              <HCBigButton
                icon="uil:heart"
                :label="String($_.get(article, 'metadata.likes', 0) + parseInt(postLiked))"
                color="negative"
                class="float-right q-mt-md"
                @click="likePost"
              />
              <QDialog
                v-model="postLikesEasterEgg"
                @hide="postLikesEasterEgg = false"
              >
                <QCard>
                  <QCardSection class="text-center">
                    <AppContent path="pages.blog.post_thanks_easter_egg" />
                  </QCardSection>
                  <QCardSection>
                    <div class="flex items-center">
                      <div class="flex justify-center items-center">
                        <QBtn
                          v-for="(link, linkType) of links"
                          :key="linkType"
                          round
                          :color="linkType"
                          :href="link.url"
                          :icon="link.icon"
                          target="blank"
                          size="sm"
                          class="q-mx-xs"
                          @click="cc(link.url)"
                        />
                      </div>
                    </div>
                  </QCardSection>
                </QCard>
              </QDialog>
            </div>
          </div>

          <!-- CTA Pricing -->
          <div class="row justify-around items-center q-mt-md text-center">
            <AppContent
              :path="`pages.${trnBlog}.promote_title`"
              class="text-h6 text-grey-7 text-weight-medium q-mt-lg"
            />
            <AppContent
              :path="`pages.${trnBlog}.promote_subtitle`"
              class="text-grey-5 q-my-md"
            />
            <HCBigButton
              :label="`pages.${trnBlog}.promote_button`"
              :href="$web({ name: 'pricing' })"
            />
          </div>
        </div>

        <!-- platformAds -->
        <div
          v-if="!onMobile"
          class="col-2 column q-pr-sm justify-start"
        >
          <div
            v-for="(platformAd, iplatformAd) of platformAds"
            :key="iplatformAd"
            class="col-2"
          >
            <QCard
              class="hc-link br-25 hc-shadow cursor-pointer"
              @click="handleAdClick(platformAd)"
            >
              <div
                v-if="String(platformAd.fields.label).length"
                class="text-grey-5 text-caption text-italic q-pl-sm"
              >
                Publicité : {{ platformAd.fields.label ?? '' }}
              </div>
              <img
                :src="cdnImg(platformAd.fields.media)"
                loading="lazy"
                :alt="platformAd.fields.label ?? 'Pub'"
                style="width:100%"
              >
              <ActionTooltip
                v-if="platformAd.fields.label"
                :str="platformAd.fields.label"
              />
            </QCard>
          </div>
        </div>
      </div>
    </QPage>
    <HCLoading v-else />
  </div>
</template>

<script>
import ArticleCard from 'hc-core/components/cards/article-card'
import LazyYtVideo from 'hc-core/components/tools/lazy-yt-video'
import { cc, embedURL, stripTags, addContactToNewsletter } from 'hc-core/composables/misc.js'

export default {
  components: { LazyYtVideo, ArticleCard },
  async preFetch ({ store, currentRoute }) {
    await store.dispatch('content/fetchArticle', { locale: 'fr', slug: currentRoute.params.slug })
  },
  data () {
    return {
      searchQuery: '',
      titles: [],
      editedContent: '',
      postLiked: 0,
      postLikesEasterEgg: false,
      newsletterEmail: '',
      newsletterFirstname: ''
    }
  },
  computed: {
    onMobile () { return this.$q.platform.is.mobile },
    article () { return this.content.article },
    content () { return this.$store.state.content },
    nodes () { return this.editedContent.split(/\[([^\]]+)]/) },
    trnBlog () { return process.env.HC_PLATFORM === 'VDC' ? 'blog_vdc' : 'blog' },
    articleDate () { return this.$_.defaultTo(this.$_.get(this.article, 'publishedDate', null), this.$_.get(this.article, 'createdDate', null), new Date()) },
    currentUrl () { return `${process.env.HC_BASE_URL}${this.$route.path}` },
    links () {
      return {
        facebook: { icon: 'uil:facebook-f', url: `https://www.facebook.com/sharer/sharer.php?u=${this.currentUrl}` },
        linkedin: { icon: 'uil:linkedin-alt', url: `https://www.linkedin.com/shareArticle?mini=true&url=${this.currentUrl}` },
        twitter: { icon: 'uil:twitter-alt', url: `http://twitter.com/share?url=${this.currentUrl}` },
        primary: { icon: 'uil:link', url: this.currentUrl, cc: true }
      }
    },
    platformAds () { return this.$_.get(this.$store, 'state.content.platformAds.results', []) }
  },
  watch: {
    $route: {
      deep: true,
      handler: async function (to, from) {
        try {
          const res = await this.$store.dispatch('content/fetchArticle', { locale: 'fr', slug: this.$route.params.slug })
          if (res) {
            // await this.$store.commit('content/setMetadata', {
            //   title: res.fields.title,
            //   description: res.fields.subtitle,
            //   image: res.fields.cover,
            //   type: 'article'
            // })
            // Build summary
            this.generateSummary()
          }
        } catch (e) {
          this.useLogger(e)
        }
      }
    },
  },
  async mounted () {
    this.editedContent = this.article.fields.content
    this.generateSummary()
    // Put in store related articles
    const tempoNodes = this.article.fields.content.split(/\[([^\]]+)]/)
    for (const tempoNode in tempoNodes) {
      if (tempoNodes[tempoNode].split('=')[0] === 'article') {
        await this.$store.dispatch('content/fetchArticle', { locale: 'fr', slug: tempoNodes[tempoNode].split('/').slice(-1)[0], relatedArticle: true })
      }
    }
  },
  methods: {
    cc,
    embedURL,
    addContactToNewsletter,
    nodeType (node) { return node.split('=')[0] },
    getRelatedArticle (node) { return this.content.relatedArticles[this.$_.last(node.split('/'))] },
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    platformAdAtIndex (node) {
      const index = node.split('=')[1]
      if (index && this.$_.get(this.platformAds, `[${index}]`, false)) return this.platformAds[index]
      else return null
    },
    async generateSummary () {
      this.titles = []
      const tmpTitles = this.editedContent.match(/<h[1-6].*?>(.*?)<\/h[1-6]>/gmus)
      if (Array.isArray(tmpTitles)) {
        for (const tmpTitle of tmpTitles) {
          // Article will only have h3 & h4 in their corpse
          const editedTitle = stripTags(tmpTitle.replace(/<h\d/, `$& id="titre_${this.titles.length}"`)
            .replaceAll(/h[1-3]/gu, 'h3')
            .replaceAll(/h[4-6]/gu, 'h4')
          )
          this.editedContent = this.editedContent.replace(tmpTitle, editedTitle)
          const span = document.createElement('span')
          span.innerHTML = editedTitle

          // Check for subart tile or part title
          if ([4, 5, 6].includes(parseInt(editedTitle[2]))) {
            this.$_.set(this.titles, `[${this.titles.length - 1}].children`, this.$_.concat(this.$_.get(this.titles, `[${this.titles.length - 1}].children`, []), [span.innerText]))
          } else this.titles.push({ text: span.textContent || span.innerText })

          // Insert ads before titles so no separation between parts' title and content
          if (this.onMobile) this.editedContent = this.editedContent.replace(editedTitle, `[platformAd=${this.titles.length - 1}] ${editedTitle}`)
        }
      }

      // Logs views on platformAds
      if (this.platformAds.length) {
        try {
          for (const platformAd of this.platformAds) await this.$store.commit('content/setAnalyticsSlug', platformAd.name)
        } catch (e) { /* No warning since not vital */ }
      }
    },
    async likePost () {
      try {
        if (!this.postLiked) {
          await this.$store.dispatch('common/sendCustomEvent', {
            type: 'public_update_entry',
            objectId: this.article.id,
            emitterId: 'happycab-v3',
            metadata: {
              entryKeyToUpdate: 'likes',
              entryValueToAdd: 1
            }
          })
        }
        this.postLiked += 1
        if (this.postLiked > 3) this.postLikesEasterEgg = true
      } catch (e) {
        this.useLogger(e)
      }
    },
    async submitNewsletter () {
      try {
        await this.addContactToNewsletter(this.newsletterEmail, this.newsletterFirstname)
        this.newsletterEmail = ''
        this.newsletterFirstname = ''
      } catch (e) { this.useLogger(e) }
    },
    async handleAdClick (platformAd) {
      if (!platformAd) return
      if (this.$_.get(platformAd, 'fields.link', false)) window.open(platformAd.fields.link, '_blank')
      try {
        // Dispatch update for analytics
        await this.$store.dispatch('common/sendCustomEvent', {
          type: 'public_update_entry',
          objectId: platformAd.id,
          emitterId: 'happycab-v3',
          metadata: {
            entryKeyToUpdate: '_analytics.clicks',
            entryValueToAdd: 1
          }
        })
      } catch (e) { /* No warning since not vital */ }
    }
  },
}
</script>

<style lang="sass">
#blogPost h1
  font-weight: 700!important
  font-size: 32px!important
  line-height: 42px!important
  color: $grey-7!important

#blogPost h2
  font-weight: 400!important
  font-size: 18px!important
  line-height: 32px!important
  color: $grey-6!important

#blogPost h3
  font-weight: 500!important
  font-size: 24px!important
  line-height: 32px!important
  scroll-margin-top: 90px
  color: $grey-7!important

#blogPost h4, #blogPost h5, #blogPost h6
  font-weight: 500!important
  font-size: 18px!important
  line-height: 32px!important
  scroll-margin-top: 90px
  color: $grey-7!important
  margin: 2em auto!important

#blogPost b
  font-weight: 500!important

#blogPost img
  max-width: 100%!important
</style>

<template>
  <ArticleBuild />
</template>

<script>
import ArticleBuild from 'hc-core/components/builds/article-build'

export default {
  components: { ArticleBuild },
}
</script>
